html{
  width: 100%;
}

/* Global Styles */
body {
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #000000 !important;
}

.padding-right{
padding-right: 100px;
}

.padding-left{ 
  padding-left: 100px;
}

.padding-left-1200{
padding-left: 0;
}

.s{
  font-size: 14px;
}
.md{
  font-size: 16px;
}
.lg{
  font-size: 18px;
}

.xl{
  font-size: 24px;
}

h3{
  font-size: 45px;
}

h2{
  font-size: 48px;
}

h1{
  font-size: 80px;
  font-family: "Poppins", sans-serif !important;
}

.display-block-1340{
  display: none !important;
}

.display-none-1200{
  display: block;
}

.display-none-768{
  display: block;
}

.display-none-576{
  display: block;
}

@media screen and (max-width: 1450px) {

  .hover-title{
    margin-top: 0px !important;
  }
  .hover-text{
    font-size: 18px;
    margin-top: 0px !important;
    margin-left: 35px !important;
  }
}


@media screen and (max-width: 1340px) {

  .hover-text{
    font-size: 18px;
    margin-top: 0px !important;
  }

  .padding-right{
    padding-right: 50px;
    }
    
    .padding-left{ 
      padding-left: 50px;
    }

    .display-none-1340{
      display: none;
    }
    .display-block-1340{
      display: flex !important;
    }

}


@media screen and (max-width: 1200px) {
  .display-none-1200{
    display: none;
  }

  .padding-left-1200{
    padding-left: 50px;
    }

    .our-service-background{
      background-size: 300% !important;
      padding-bottom: 130px !important;
      padding-top: 130px !important;
      background-position: right bottom 60% !important;
    }



}

/* Responsive font sizes */
@media screen and (max-width: 768px) {
  .s {
    font-size: 12px;
  }
  .md {
    font-size: 14px;
  }
  .lg {
    font-size: 16px;
  }
  .xl {
    font-size: 20px;
  }
  h3 {
    font-size: 36px;
  }
  h2 {
    font-size: 40px;
  }
  h1 {
    font-size: 60px;
  }
  .display-none-768{
    display: none;
  }

  .our-service-background{
    background-size: 300% !important;
    padding-top: 20px;
    padding-bottom: 20px;
        background-position: right  bottom  !important;
  }

  .logo-image {
    width: 150px !important; /* Adjust size as needed */
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .s {
    font-size: 10px;
  }
  .md {
    font-size: 12px;
  }
  .lg {
    font-size: 14px;
  }
  .xl {
    font-size: 18px;
  }
  h3 {
    font-size: 30px;
  }
  h2 {
    font-size: 36px;
  }
  h1 {
    font-size: 38px;
  }
  .display-none-576{
    display: none;
  }

  .padding-left{
    padding-left: 20px;
  }
  .padding-right{
    padding-right: 20px;
  }

  .padding-left-1200{
    padding-left: 20px;
    }

    .our-service-background{
      background-size: 300% !important;
      background-position: right  bottom 20%  !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }


}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-image {
  width: 200px; /* Adjust size as needed */
  height: auto;
}

.nav-button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  z-index: 1;
}


.filled-button{
  background-color:  #DDFF0D;
  color: #000000;
  border-radius: 8px;
  width: 126px;
  height: 48px;
  z-index: 1;
  border: none;
}


/* OUR SERVICES */

.chartsvg{
  background-image: url("assets/ChartSvg.svg") ;
  background-repeat: no-repeat;
  background-size: contain;
  height: 500px;
  width: 1600px;
}

.svg-icon-1:hover path {
  fill: #FFFFFF; 
}
.svg-icon-2:hover path {
  fill: #FFFFFF; 
}
.svg-icon-3:hover path {
  fill: #FFFFFF; 
}
.svg-icon-4:hover path {
  fill: #FFFFFF; 
}

.hover-text {
  display: none; 
}

.hover1:hover > .hover-text  {
  display: block; 
}
.hover1:hover  .hover-title  {
  display: none; 
}

.hover2:hover > .hover-text {
  display: block; 
}
.hover2:hover > .hover-title  {
  display: none;
}

.hover3:hover > .hover-text {
  display: block; 
}
.hover3:hover > .hover-title  {
  display: none; 
}

.hover4:hover > .hover-text {
  display: block;
}
.hover4:hover > .hover-title  {
  display: none; 
}


.hover1-mobile:hover > .hover-text  {
  display: block; 
}
.hover1-mobile:hover  .hover-title  {
  display: none; 
}

.hover2-mobile:hover > .hover-text {
  display: block; 
}
.hover2-mobile:hover > .hover-title  {
  display: none;
}

.hover3-mobile:hover > .hover-text {
  display: block; 
}
.hover3-mobile:hover > .hover-title  {
  display: none; 
}

.hover4-mobile:hover > .hover-text {
  display: block;
}
.hover4-mobile:hover > .hover-title  {
  display: none; 
}


.card-our-services{
  padding: 32px 32px 22.65px 32px;
  border: 1px solid #20DDA240;
  border-radius: 15.1px;
  background:  #FFFFFF0D;
  width: 350px;
  height: 250px;
  justify-content: center;
}

.our-service-background{
  background-image: url("assets/ourServicesBackground.png");
  background-size: 90%;
  padding-top: 150px;
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-position: right  bottom 60%;
}


/* BANNER */

.banner {
  display: flex;
  justify-content: space-between;
}

.banner-image {
  max-width: 100%; /* Ensure image fits container */

  height: 100% !important;
  width: 100%;
  margin-top: -100px !important;
}

.image-container{
  background-image: url("assets/BannerBackground.png");
  background-size: cover;
  background-position: center;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-title {
  margin-bottom: 20px;
  white-space: pre-line;
}

/* FORM */

.input-form{
  border-radius: 8px;
  padding: 12px;
  background-color: #FFFFFF1A  !important;
  border: 1px solid #FFFFFF1A !important;
  height: 48px;
  width: 100%;
  color: white;
}

.input-form:focus{
  background-color:  #FFFFFF1A !important;
  border: 1px solid white;
  color: white;
}


.form-component{
  display: flex;
  justify-content: space-between;
}

.form-control{
  background-color:  #FFFFFF1A  !important;
  border: 1px solid #FFFFFF1A;
  color: white;
}


.form-control:focus{
  background: #FFFFFF1A ;
  border: 1px solid white;
  color: white;
}


textarea{
  height: 150px;
}

label{
  margin-bottom: 10px;
}

.submit-button{
  background-color:  #20DDA2;
  width: 140px;
  height: 40px;
  padding: 0px 24px 0px 24px;
  border-radius: 8px;
  border: none;
}


input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
  display: inline-block;
  width: 17px; /* Set the width and height according to your design */
  height: 17px;
  background-color: black; /* Change to your desired background color */
  border: 1px solid #808080;
  border-radius: 3px;
}

/* Style the custom checkbox when checked */
input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #DDFF0D; /* Change to your desired background color when checked */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset rgba(255, 255, 255, 0.051);
}


/* CARDS */


.card-1{
 padding: 24px 32px 24px 32px;
 border: 1px solid #20DDA240;
 border-radius: 16px;
 background: linear-gradient(99.7deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%);
 justify-content: center;
}

.card-2 {
  padding: 48px 60px 48px 60px;  
  border: 1px solid #FFFFFF33;
  background: rgba(255, 255, 255, 0.051);
  border-radius: 16px;
}

.card-carousel{
  padding: 48px 60px 48px 60px;  
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #FFFFFF26;
 background: #FFFFFF0D;

  border-radius: 16px;
}

.carousel-container{
  padding-bottom: 75px;
}

.card-title{
  font-weight: 600;
  font-size: 48px;
  color: #20DDA2;
}

.card-subtitle{
  font-weight: 600;
  font-size: 32px;
  color: #20DDA2;

}

.color-safe-software{
  color: #20DDA2;
}

/* FOOTER */

.line {
  width: 100%;
  height: 1px; /* Adjust thickness as needed */
  background-color: white; /* Change color as needed */
}

.footer-button{
  text-decoration: underline;
}

/* TESTIMONIAL CAROUSLE */

.testimonial-background{
  background: linear-gradient(0deg, rgba(221, 255, 13, 0.05), rgba(221, 255, 13, 0.05)),
  linear-gradient(55.22deg, rgba(0, 0, 0, 0) 40.12%, #000000 100%);
  padding-top: 200px;
  padding-bottom: 200px;
}



body{
  background-color: #131626;
}

.valueDisplay{
  color: #f7bd8f;
  margin-left: 20px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight:100;
}

.Slider{
  -webkit-appearance: none;
  background: #131626;
  height: 16px;
  width: 250px;
  border-radius: 8px;
  border: 1px solid #f7bd8f;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width:12px;
  height:12px;
  border-radius: 6px;
  background: #f7bd8f;
  outline: none;
}